import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import PhoneIcon from '@material-ui/icons/Phone'
import FavoriteIcon from '@material-ui/icons/Favorite'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import HelpIcon from '@material-ui/icons/Help'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import ThumbDown from '@material-ui/icons/ThumbDown'
import ThumbUp from '@material-ui/icons/ThumbUp'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { StaticSurveyQuestionView, InterventionsTriedTable } from '@components'
import Color from 'color'
import { Alert, AlertTitle } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDumbbell, faPills, faUtensils, faPrescriptionBottleAlt } from '@fortawesome/free-solid-svg-icons'

const QUESTIONS = {
	DIET_CHANGES: 'Have these dietary changes helped any of your symptoms?',
	DIET: 'Have these diets helped any of your symptoms?',
	MEDS: 'Have these medication changes helped any of your symptoms?',
	LIFESTYLE: 'Have these lifestyle changes helped any of your symptoms?',
	ANTIBIOTICS: 'Have these antibiotics helped any of your symptoms?',
}

function TabContent(props) {
	const answer = props.question.data.find((q) => q.question === props.q)?.answer
	return (
		<>
			<StaticSurveyQuestionView
				showNav={props.showNav}
				requestData={props.requestData}
				survey={props.survey}
				question={props.q}
				answer={answer}
			/>
		</>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`,
	}
}

const styles = (theme) => ({
	tabs: {
		// borderRight: `1px solid ${theme.palette.divider}`,
		// [theme.breakpoints.down('md')]: {
		// },
	},
	expand: {
		flexGrow: 1,
		marginBottom: theme.spacing(1),
	},
	inlineTab: {},
	distinguishedTab: {
		margin: 15,
		borderColor: 'white',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	tabsBackground: {
		minWidth: 190,
	},
	tabPanel: {
		maxHeight: 460,
		overflow: 'auto',
	},
	titleBar: {
		padding: theme.spacing(1),
	},
	interventionGroupHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? Color(theme.palette.background.paper).lighten(0.7)
				: Color(theme.palette.background.paper).darken(0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
})

class SurveyQuestionsBrowser extends Component {
	state = {
		value: 0,
	}

	handleChange = (event, newValue) => {
		this.setState({ value: newValue })
	}

	render() {
		const classes = this.props.classes

		const generalGI = this.props.survey.data.find((survey) => {
			return survey.path === 'shared/general_gi.json'
		})

		return generalGI ? (
			<>
				{/* <Grid container className={classes.interventionGroupHeader}>
					<Typography variant="h5" color="textSecondary" component="h5">
          Patient Reported Experiment History
					</Typography>
				</Grid> */}
				<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
					<Toolbar>
						<Typography variant="h5" color="textSecondary" component="h5">
							Reported Experiment History
						</Typography>
					</Toolbar>
				</AppBar>

				<AppBar position="static" color="primary">
					<Tabs
						value={this.state.value}
						onChange={this.handleChange}
						scrollButtons="auto"
						// orientation="vertical"
						variant="scrollable"
						// indicatorColor="primary"
						// textColor="primary"
						className={classes.tabs}
					>
						<Tab
							className={classNames(classes.inlineTab, classes.distinguishedTab)}
							label="Summary"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(0)}
						/>
						<Tab
							className={classes.inlineTab}
							label="Lifestyle Changes"
							// icon={<FontAwesomeIcon icon={faDumbbell} />}
							{...a11yProps(1)}
						/>
						<Tab
							className={classes.inlineTab}
							label="Diets"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(2)}
						/>
						<Tab
							className={classes.inlineTab}
							label="Dietary Changes"
							// icon={<FontAwesomeIcon icon={faUtensils} />}
							{...a11yProps(3)}
						/>
						<Tab
							className={classes.inlineTab}
							label="Antibiotics"
							// icon={<FontAwesomeIcon icon={faPrescriptionBottleAlt} />}
							{...a11yProps(4)}
						/>
						<Tab
							className={classes.inlineTab}
							label="Medications"
							// icon={<FontAwesomeIcon icon={faPills} />}
							{...a11yProps(5)}
						/>
					</Tabs>
				</AppBar>
				<Grid container className={classes.tabPanel}>
					<TabPanel value={this.state.value} index={0} className={classes.expand}>
						<InterventionsTriedTable question={this.props.question} />
					</TabPanel>
					<TabPanel value={this.state.value} index={1} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.LIFESTYLE}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={2} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.DIET}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={3} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.DIET_CHANGES}
						/>
					</TabPanel>

					<TabPanel value={this.state.value} index={4} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.ANTIBIOTICS}
						/>
					</TabPanel>
					<TabPanel value={this.state.value} index={5} className={classes.expand}>
						<TabContent
							requestData={this.props.assembleSurvey}
							survey={generalGI}
							question={this.props.question}
							q={QUESTIONS.MEDS}
							showNav
						/>
					</TabPanel>
				</Grid>
			</>
		) : (
			<Grid item xs={12}>
				<Alert variant="outlined" severity="error">
					<AlertTitle>Not yet completed General GI Survey</AlertTitle>
					Once this survey has been completed in the Dieta Mobile app, your information will populate this
					section here
				</Alert>
			</Grid>
		)
	}
}

const mapStateToProps = ({ survey, question }) => {
	return {
		survey,
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(SurveyQuestionsBrowser)
