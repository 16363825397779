import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Alert, AlertTitle } from '@material-ui/lab'

import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { InterventionsOptionsTable } from '@components'

const styles = (theme) => {
	return {
		paper: {
			margin: 'auto',
			overflow: 'hidden',
		},
		tabs: {
			marginBottom: theme.spacing(4),
		},
		createYourOwnContainer: {
			borderRadius: 5,
			borderWidth: 1,
			borderColor: theme.palette.primary[500],
			borderStyle: 'solid',
			padding: '16px 26px',
			backgroundColor: theme.palette.background.default,
		},
		createButton: {
			width: '100%',
		},
	}
}

class AssignInterventionsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	componentDidMount = () => {
		const lastFetched = this.props.analytics.interventionRecs.lastFetched
		if (!lastFetched) {
			this.hydrateData()
		} else if (lastFetched + 2 * 60 * 60 * 1000 <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		if (!this.props.analytics.interventionRecs.loading.read) {
			this.props.fetchInterventionRecs()
			this.props.fetchInterventionsSuperset()
		}
	}

	onRefresh = () => {
		this.props.fetchInterventionRecs()
		this.props.fetchInterventionsSuperset()
	}

	render() {
		const {
			classes,
			intervention,
			survey,
			question,
			assembleSurvey,
			title,
			defaultOrder,
			clearCurrentIntervention,
			handleClickOpenInterventionCreate,
		} = this.props
		const now = new Date().getTime()
		const interventionStates = intervention?.data.reduce(
			(acc, item) => {
				if (!item.occurred_at) {
					acc.queued.push(item)
				} else {
					const occurred_at = new Date(item.occurred_at)
					if (Number.isInteger(item.effect)) {
						acc.historical.push(item)
					} else if (now <= occurred_at.getTime() + item.duration * 1000) {
						acc.active.push(item)
					} else {
						acc.concluding.push(item)
					}
				}
				return acc
			},
			{
				queued: [],
				active: [],
				concluding: [],
				historical: [],
			}
		)

		const activeInterventions = [
			...interventionStates.concluding,
			...interventionStates.queued,
			...interventionStates.active,
		]
		const showToast = activeInterventions.length
		const subject = this.props.patient.current ? 'Patient is' : "You're"
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12} sm={10}>
						<Grid container spacing={4}>
							{showToast ? (
								<Grid item xs={12}>
									<Alert variant="outlined" severity="warning">
										<AlertTitle>{subject} currently on a self experiment</AlertTitle>
										We recommend keeping the active self-experiments to a minimum to more clearly
										isolate trigger / symptom relationships.
									</Alert>
								</Grid>
							) : (
								<></>
							)}
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<InterventionsOptionsTable
										title="Dieta Recommended Self Experiments"
										interventions={this.props.analytics.interventionRecs.top}
										handleClickOpenInterventionCreate={this.props.handleClickOpenInterventionCreate}
										setCurrentIntervention={this.props.setCurrentIntervention}
									/>
								</Paper>
							</Grid>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<InterventionsOptionsTable
										title="All Self Experiments"
										interventions={this.props.intervention.collection}
										handleClickOpenInterventionCreate={this.props.handleClickOpenInterventionCreate}
										setCurrentIntervention={this.props.setCurrentIntervention}
									/>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		)
	}
}

AssignInterventionsContent.propTypes = {
	classes: PropTypes.object.isRequired,
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		collection: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	analytics: PropTypes.shape({
		interventionRecs: PropTypes.shape({
			lastFetched: PropTypes.number,
			top: PropTypes.array,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
	}),
	fetchInterventionRecs: PropTypes.func.isRequired,
	setCurrentIntervention: PropTypes.func.isRequired,
	clearCurrentIntervention: PropTypes.func.isRequired,
}

const mapStateToProps = ({ patient, intervention, analytics }) => {
	return {
		patient,
		intervention,
		analytics,
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	withTranslation()
)(AssignInterventionsContent)
