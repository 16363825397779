import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import TabBar from 'molecules/TabBar'
import { Button } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { LinearProgress, InterventionCreateDialog } from '@components'
import ViewInterventions from './ViewInterventions'
import AssignInterventions from './AssignInterventions'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import moment from 'moment'

const styles = (theme) => ({
	paper: {
		maxWidth: 936,
		margin: 'auto',
		overflow: 'hidden',
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	searchInput: {
		fontSize: theme.typography.fontSize,
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing(1),
	},

	container: {
		padding: '48px 36px 0',
	},
})
const tabNames = ['View', 'Assign']

function InterventionsContent(props) {
	const { location, classes } = props
	const [openInterventionCreate, setInterventionCreate] = React.useState(false)
	const [interventionConfirm, setInterventionConfirm] = React.useState(false)
	const [query, setQuery] = useQueryParams({
		tab: withDefault(StringParam, tabNames[0]),
	})
	const onTabChange = (newValue, tabName) => {
		setQuery({ tab: tabName })
	}

	const handleClickOpenInterventionCreate = (confirm) => {
		setInterventionCreate(true)
		setInterventionConfirm(confirm)
	}
	const handleCloseInterventionCreate = () => {
		setInterventionCreate(false)
		props.clearCurrentIntervention()
	}

	const doUpdate = async () => {
		props.getQuestions()
		props.getSurveys({ historical: true })
	}

	useEffect(() => {
		if (
			!props.survey.lastFetched ||
			(props.survey.lastFetched + 2 * 60 * 60 * 1000 <= new Date().getTime() && !props.survey.loading.read)
		) {
			props.getQuestions()
			props.getSurveys({ historical: true })
		}
	}, [])

	const isLoading =
		props.intervention.loading.read ||
		props.question.loading.read ||
		props.survey.loading.read ||
		props.analytics.interventionRecs.loading.read

	return (
		<>
			<TabBar tabNames={tabNames} tab={query.tab} onTabChange={onTabChange} />
			<LinearProgress visible={isLoading} color="primary" />
			{
				{
					View: <ViewInterventions isLoading={isLoading} />,
					Assign: (
						<AssignInterventions
							isLoading={isLoading}
							handleClickOpenInterventionCreate={handleClickOpenInterventionCreate}
						/>
					),
				}[query.tab]
			}

			<InterventionCreateDialog
				open={openInterventionCreate}
				auth={props.auth}
				finalize={interventionConfirm}
				intervention={props.intervention}
				addIntervention={props.addIntervention}
				setCurrentIntervention={props.setCurrentIntervention}
				clearCurrentIntervention={props.clearCurrentIntervention}
				handleClose={handleCloseInterventionCreate}
			/>
		</>
	)
}

InterventionsContent.propTypes = {
	classes: PropTypes.object.isRequired,
	auth: PropTypes.shape({
		sub: PropTypes.string.isRequired,
	}),
	intervention: PropTypes.shape({
		current: PropTypes.object,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	survey: PropTypes.shape({
		lastFetched: PropTypes.number,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	analytics: PropTypes.shape({
		interventionRecs: PropTypes.shape({
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
	}),
	question: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	addIntervention: PropTypes.func.isRequired,
	setCurrentIntervention: PropTypes.func.isRequired,
	clearCurrentIntervention: PropTypes.func.isRequired,
	getQuestions: PropTypes.func.isRequired,
	getSurveys: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, intervention, survey, analytics, question }) => {
	return {
		auth,
		intervention,
		survey,
		analytics,
		question,
	}
}

export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(InterventionsContent)
