import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ViewInterventionsContent from './ViewInterventionsContent'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	roleContainer: {
		paddingTop: theme.spacing(4),
	},
}))

const ViewInterventions = ({ intervention }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<ViewInterventionsContent />
				</Grid>
			</Grid>
		</div>
	)
}

ViewInterventions.propTypes = {}

const mapStateToProps = ({ intervention }) => {
	return {
		intervention,
	}
}

export default connect(mapStateToProps, actions)(ViewInterventions)
