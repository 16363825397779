import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'

import AssignInterventionsContent from './AssignInterventionsContent'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	roleContainer: {
		paddingTop: theme.spacing(4),
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}))

const AssignInterventions = ({ intervention, handleClickOpenInterventionCreate }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<AssignInterventionsContent handleClickOpenInterventionCreate={handleClickOpenInterventionCreate} />
				</Grid>
			</Grid>
			<Tooltip arrow title="Create a new self experiment from scratch" aria-label="create from scratch">
				<Fab
					variant="circular"
					aria-label={'Create New'}
					className={classes.fab}
					color={'primary'}
					onClick={handleClickOpenInterventionCreate}
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</div>
	)
}

AssignInterventions.propTypes = {}

const mapStateToProps = ({ intervention }) => {
	return {
		intervention,
	}
}

export default connect(mapStateToProps, actions)(AssignInterventions)
