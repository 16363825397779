import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'

import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { InterventionsTable, ActiveInterventionsTable } from '@components'
import SurveyQuestionsBrowser from './SurveyQuestionsBrowser'

const styles = (theme) => ({
	paper: {
		margin: 'auto',
		overflow: 'hidden',
	},
	tabs: {
		marginBottom: theme.spacing(4),
	},
})

class ViewInterventionsContent extends Component {
	constructor(props) {
		super(props)
	}

	state = {}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	componentDidMount = () => {
		const lastFetched = this.props.intervention.lastFetched
		if (!lastFetched) {
			this.hydrateData()
		} else if (lastFetched + 2 * 60 * 60 * 1000 <= new Date().getTime()) {
			this.hydrateData()
		}
	}

	hydrateData = () => {
		if (!this.props.intervention.loading.read) {
			this.props.fetchInterventions()
		}
	}

	onRefresh = () => {
		this.props.fetchInterventions()
	}

	hydrateInterventionCompliance = (activeIntervention) => {
		// const fromDate = new Date();
		// const toDate = new Date();
		// const from = new Date(
		//   fromDate.setDate(fromDate.getDate() - 7),
		// ).toISOString();
		// const to = new Date(toDate.setDate(toDate.getDate() + 2)).toISOString();
		this.props.fetchInterventionCompliance({
			user_intervention_id: activeIntervention.id,
		})
	}

	render() {
		const {
			classes,
			intervention,
			symptomScore,
			survey,
			question,
			assembleSurvey,
			title,
			defaultOrder,
		} = this.props
		const now = new Date().getTime()
		const interventionStates = intervention?.data.reduce(
			(acc, item) => {
				if (!item.occurred_at) {
					acc.queued.push(item)
				} else {
					const occurred_at = new Date(item.occurred_at)
					if (Number.isInteger(item.effect)) {
						acc.historical.push(item)
					} else if (now <= occurred_at.getTime() + item.duration * 1000) {
						acc.active.push(item)
					} else {
						acc.concluding.push(item)
					}
				}
				return acc
			},
			{
				queued: [],
				active: [],
				concluding: [],
				historical: [],
			}
		)

		const activeInterventions = [
			...interventionStates.concluding,
			...interventionStates.queued,
			...interventionStates.active,
		]
		const historicalInterventions = interventionStates.historical || []
		return (
			<>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item xs={12} sm={10}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Paper
									className={classes.paper}
									style={{
										maxHeight: 600,
									}}
								>
									<SurveyQuestionsBrowser className={classes.tabs} />
								</Paper>
							</Grid>
							<Grid item xs={12}>
								<Paper
									className={classes.paper}
									// style={{
									// 	height: 500,
									// }}
								>
									<ActiveInterventionsTable
										loading={intervention.loading.read}
										symptomScore={symptomScore}
										interventions={activeInterventions}
										interventionCompliance={this.props.intervention.interventionCompliance}
										onRefresh={this.onRefresh}
										hydrateInterventionCompliance={this.hydrateInterventionCompliance}
									/>
								</Paper>
							</Grid>
							{historicalInterventions.length ? (
								<Grid item xs={12}>
									<Paper className={classes.paper} style={{}}>
										<InterventionsTable
											loading={intervention.loading.read}
											symptomScore={symptomScore}
											interventions={historicalInterventions}
											onRefresh={this.onRefresh}
											interventionCompliance={this.props.intervention.interventionCompliance}
											hydrateInterventionCompliance={this.hydrateInterventionCompliance}
										/>
									</Paper>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
					</Grid>
				</Grid>
			</>
		)
	}
}

ViewInterventionsContent.propTypes = {
	classes: PropTypes.object.isRequired,
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
			loading: PropTypes.shape({
				create: PropTypes.bool.isRequired,
				read: PropTypes.bool.isRequired,
				update: PropTypes.bool.isRequired,
				delete: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	survey: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	question: PropTypes.shape({
		data: PropTypes.array.isRequired,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	fetchInterventions: PropTypes.func.isRequired,
	fetchInterventionCompliance: PropTypes.func.isRequired,
	assembleSurvey: PropTypes.func.isRequired,
}

const mapStateToProps = ({ intervention, symptomScore, survey, question }) => {
	return {
		intervention,
		symptomScore,
		survey,
		question,
	}
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	withTranslation()
)(ViewInterventionsContent)
